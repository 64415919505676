import { Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router'
import { jumboinfo, jumboinfo2, jumboinfo3 } from "../jumboinfo";

import { products } from '../products';
declare var jQuery:any;
declare var $ :any;

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css']
})

export class LandingComponent implements OnInit {
  products= products;
  jumboinfo = jumboinfo;
  jumboinfo2 = jumboinfo2;
  jumboinfo3 = jumboinfo3;
  router; 
    
    constructor() {}
    
    

    ngOnInit() { 
       var noWrap = document.getElementById("masterDiv");
       noWrap.classList.remove("wrapper");
       }

    refresh1():any {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(["/industry4.0"])); 
    }

    refresh2():any {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(["/iiot"])); 
    }

    refresh3():any {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(["/automation"])); 
    }


    }

 
//page animation control
$(document).ready(function(){
  var options = {
    animateThreshold: 150,
    scrollPollInterval: 20
}
            $('.aniview').AniView(options);
        });



//main function for landing page, called at page init.
jQuery(document).ready(function() {
  $("#landingImgMobile").hide();
  
    function reorder() {
     
      if ($(window).width() < 1500) {
        $(".newsMTConnectLand").removeClass("col-4").addClass("col-12");
        $(".newsImageLand").removeClass("newsImageLand").addClass("newsImageLand2");

      }
      //determine window size to enable mobile media query
     else if ($(window).width() < 550) {
         
        //mobile responsive logic
        //  $(".newsImageDiv").removeClass("col-4");
         $(".newsImageLand").hide();
         $(".newsImageLand2").hide();
         $("#otherSectionImgOne").removeClass("col-6").addClass("col-12 responsive");
         $("#cardOne").css("float", "none").removeClass("col-6").addClass("col-12");
         $("#otherSectionImgTwo").removeClass("col-6").addClass("col-12 responsive").css("float","none");
         $("#cardTwo").css("float", "none").removeClass("col-6").addClass("col-12");
         $("#landingImgDesk").hide();
         $("#landingImgMobile").show();
      //window size to enable laptop media query
     } else if ($(window).width() < 1500) {
     
      $("#otherSectionImgOne").removeClass("col-6").addClass("col-12 responsive");
      $("#cardOne").css("float", "none").removeClass("col-6").addClass("col-12");
      $("#otherSectionImgTwo").removeClass("col-6").addClass("col-12 responsive").css("float","none");
      $("#cardTwo").css("float", "none").removeClass("col-6").addClass("col-12");
      $("#cardTwo2").show();
      $("#cardTwo").hide();
      $(".newsMTConnectLand").removeClass("col-12").addClass("col-4");
      $(".newsImageLand2").removeClass("newsImageLand2").addClass("newsImageLand");

   
      //window size is not mobile, enable desktop media query
    } else if ($(window).width() < 2000) {
        //desktop responsive logic
        //  $(".newsImageDiv").addClass("col-4");
         $(".newsImageLand").show();
         $("#cardTwo2").hide();
         $("#cardTwo").show();

         $("form").removeClass("responsive");
         $("#otherSectionImgOne").removeClass("col-12 responsive").addClass("col-6");
         $("#cardOne").css("float", "right").removeClass("col-12").addClass("col-6");
         $("#otherSectionImgTwo").removeClass("col-12 responsive").addClass("col-6").css("float", "right");
         $("#cardTwo").css("float", "left").removeClass("col-12").addClass("col-6");
         $(".newsMTConnectLand").removeClass("col-12").addClass("col-4");
         $(".newsImageLand2").removeClass("newsImageLand2").addClass("newsImageLand");
     }
    
    }
    //call main function on page init
    reorder();
     //re-init main function on window resize
     $(window).resize(function(){
 
         reorder();
     });
 });

 $(document).ready(function(){
  var sizeLand = window.matchMedia("(max-width: 1000px)");
  


 function mediaQuery(x) {
     if (sizeLand.matches){

         $(".newsImageLand").hide();
         $(".newsImageLand2").hide();
         $("#otherSectionImgOne").removeClass("col-6").addClass("col-12 responsive");
         $("#cardOne").css("float", "none").removeClass("col-6").addClass("col-12");
         $("#otherSectionImgTwo").removeClass("col-6").addClass("col-12 responsive").css("float","none");
         $("#cardTwo").css("float", "none").removeClass("col-6").addClass("col-12");
         $("#cardTwo2").show();
      $("#cardTwo").hide();
      
  } else {
    $(".newsImageLand").show();
    $(".newsImageLand2").show();
    $("#cardTwo2").hide();
      $("#cardTwo").show();
    
  }  

 }



mediaQuery(sizeLand);
sizeLand.addListener(mediaQuery);

});