import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LandingComponent } from './landing/landing.component';
import { Industry4Component } from './industry4.0/industry4.0.component';
import { IIotComponent } from './iiot/iiot.component';
import { AutomationComponent } from './automation/automation.component';
import { ProductsComponent } from './products/products.component';
import { ProductInfoComponent } from './product-info/product-info.component';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AppGuard } from './guard.service';
import { MTConnectComponent } from './mtconnect/mtconnect.component';

export const Routes = [
  {path: '', component: LandingComponent},
  {path: 'industry4.0', component: Industry4Component},
  {path: 'products', component: ProductsComponent},
  {path: 'products/:productId', component: ProductInfoComponent},
  {path: 'iiot', component: IIotComponent},
  {path: 'automation', component: AutomationComponent},
  {path: 'mtconnect',component: MTConnectComponent}
]
@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LandingComponent,
    Industry4Component,
    ProductsComponent,
    ProductInfoComponent,
    FooterComponent,
    IIotComponent,
    AutomationComponent,
    MTConnectComponent,
  ],
  imports: [ 
    BrowserModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgScrollbarModule,
    RouterModule.forRoot(Routes, {
      onSameUrlNavigation: 'reload'
    })

  ],
  providers: [AppGuard],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
