import { Component, OnInit } from '@angular/core';
declare var jQuery:any;
declare var $ :any;

@Component({
    selector: 'app-industry4.0',
    templateUrl: './industry4.0.component.html',
    styleUrls: ['./industry4.0.component.css']
})

export class Industry4Component implements OnInit {
    router;
    constructor() { }

    ngOnInit() { 
        var noWrap = document.getElementById("masterDiv");
        noWrap.classList.remove("wrapper");

        document.getElementById("background").style.display = "none";

        document.getElementById('root').classList.add('background2');
        
        window.scrollTo(0,0);    
    }
    
    ngOnDestroy() {
            document.getElementById('root').classList.remove("background2");
        }
      
}
//page animation control
$(document).ready(function(){
    var options = {
      animateThreshold: 150,
      scrollPollInterval: 20
  }
              $('.aniview').AniView(options);
  
          });



$(document).ready(function(){
    var size = window.matchMedia("(max-width: 1300px)");


   function mediaQuery(x) {
       if (size.matches){
      
        $(".switch").removeClass("col-6").addClass("col-xs-12");
        
    } else {
        $(".switch").removeClass("col-xs-12").addClass("col-6");
    }

   }

  mediaQuery(size);
  size.addListener(mediaQuery);



});
          