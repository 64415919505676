import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
declare var jQuery:any;
declare var $ :any;



@Component({
    selector: 'app-nav-bar',
    templateUrl: 'nav-bar.component.html',
    styleUrls: ['/nav-bar.component.css']
})

export class NavBarComponent implements OnInit {
    router;
    
    constructor(@Inject(DOCUMENT) document) { }

    ngOnInit() {}

    refresh1():any {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(["/industry4.0"]));
    }

    refresh2():any {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(["/iiot"])); 
    }

    refresh3():any {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(["/automation"])); 
    }

    refresh4():any {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
      this.router.navigate(["/mtconnect"])); 
  }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
       if (window.pageYOffset > 0) {
         let element = document.getElementById('header');
         element.classList.add('sticky');
       } else {
        let element = document.getElementById('header');
          element.classList.remove('sticky'); 
       }
    }

    mobileNav() {
      var x = document.getElementById("nav");
      if (x.className === "nav") {
        x.className += " responsive";
      } else {
        x.className = "nav";
      }
    }
  

}

$(function(){
    $('a').each(function() {
      if ($(this).prop('href') == window.location.href) {
        $(this).addClass('current');
      }
    });
  });

$(document).ready(function(){
  var size = window.matchMedia("(min-width: 1100px)");
  var size2 = window.matchMedia("(max-width: 1100px )");

 function mediaQuery(x) {
     if (size.matches){
    
      $("#contactUsResponsive").hide();
      $("#contactUs").show();  

  } else if (size2.matches){
    $("#contactUsResponsive").show();
    $("#contactUs").hide();
  } 

 }

mediaQuery(size);
size.addListener(mediaQuery);



});