import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'
import { products} from '../products';

declare var jQuery:any;
declare var $ :any;
 
@Component({
    selector: 'app-product-info',
    templateUrl: './product-info.component.html',
    styleUrls: ["./product-info.component.css"], 
})

export class ProductInfoComponent implements OnInit, OnDestroy {
    product;
    navigationSubscription;

    constructor(private route: ActivatedRoute, private router: Router) {}


    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.product = products[+params.get('productId')];
        });
    
        $("#video").hide();
        
        if (this.product.name == "Alexa Option") {
            document.getElementById('root').classList.add('background1');
        } else if (this.product.landingName == "IIOT") {
            document.getElementById('root').classList.add("background2");
        } else if (this.product.landingName == "Smart Industry and Smart Factory") {
           $("#video").show();
           $("#mainInfo").css("margin-top", 50);
            
        }
        
        this.LoadOnce();        

};

LoadOnce() { 
    if(!window.location.hash) {
        window.location.href = window.location + '#loaded';
        window.location.reload();
        window.scrollTo(0,0);
    }
} 

    ngOnDestroy() {
        document.getElementById('root').classList.remove('background1');
        document.getElementById('root').classList.remove("background2");
        
    }
    
}


//page animation control
$(document).ready(function(){
    var options = {
      animateThreshold: 150,
      scrollPollInterval: 20
  }
              $('.aniview').AniView(options);
  
          });



// $(document).ready(function(){
//     var size = window.matchMedia("(max-width: 500px)");


//    function mediaQuery(x) {
//        if (size.matches){
      
//         $(".card").removeClass("col-6").addClass("col-xs-12");
        
//     } else {
//         $(".card").removeClass("col-xs-12").addClass("col-6");
//     }

//    }

//   mediaQuery(size);
//   size.addListener(mediaQuery);


// });
          
          