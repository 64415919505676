import { Component, OnInit } from '@angular/core';
declare var jQuery:any;
declare var $ :any;


@Component({
    selector: 'app-iiot',
    templateUrl: './iiot.component.html',
    styleUrls: ["./iiot.component.css"], 
    
})

export class IIotComponent implements OnInit {
    constructor() { }

    ngOnInit() { 
        var noWrap = document.getElementById("masterDiv");
        noWrap.classList.remove("wrapper");

        document.getElementById("background").style.display = "none";

        document.getElementById('root').classList.add("background3");

        window.scrollTo(0,0);
    }

    ngOnDestroy() {
        document.getElementById('root').classList.remove("background3"); 
    }

}

//page animation control
$(document).ready(function(){
    var options = {
      animateThreshold: 150,
      scrollPollInterval: 20
  }
              $('.aniview').AniView(options);
  
          });


$(document).ready(function(){
    var size = window.matchMedia("(max-width: 500px)");
    var size2 = window.matchMedia("(min-width: 1500px)");
    var size3 = window.matchMedia("(max-width: 1000px)");
    var location = "http://www.manufacturingtechnologies.org/iiot";
    var location2 = "http://www.manufacturingtechnologies.org/industry4.0";
    var location3 = "http://www.manufacturingtechnologies.org/automation";
    var location4 = "http://www.manufacturingtechnologies.org/mtconnect";
    var location5 = "http://www.manufacturingtechnologies.org/#!";
    $(".response16003").hide();
 
      
   function mediaQuery(x) {
       if (size.matches){
      
        // $(".switch").removeClass("col-6").addClass("col-xs-12");
        $("#root").removeClass("background3 background2 background4 background5");
        $("#background").show();
        $(".reasonsRow div").removeClass("col-6").addClass("col-12");
        $("#MTConnectSection div.col-6").removeClass("col-6").addClass("col-12");
        $(".response1600").removeClass("col-6").addClass("col-12").css("margin-left", "0px");
        $(".response16002").hide();
        $(".response16003").show();
        $(".newsImageDiv").removeClass("col-4").addClass("responsive col-12");
        $("#info2 .row div, #info3 .row div, #info4 .row div").removeClass("col-6").addClass("col-12");
        $("#landingImgDesk").hide();
        $("#landingImgMobile").show();
      
    } else if (size2.matches && window.location.href == location) {
        // $(".switch").removeClass("col-xs-12").addClass("col-6");
        $("#background").hide()
        $(".reasonsRow div").removeClass("col-12").addClass("col-6");
        $("#root").addClass("background3");
        $("#MTConnectSection div.col-12").addClass("col-6").removeClass("col-12");
        // $(".newsImage").show();
       
    
    } else if (size2.matches && window.location.href == location2) {
        // $(".switch").removeClass("col-xs-12").addClass("col-6");
        $("#background").hide();
        $("#root").addClass("background2");
        $(".response16003").hide();
        $(".response16002").show();
        $(".response1600").removeClass("col-12").addClass("col-6");
        $(".newsImageDiv").removeClass("col-12 responsive").addClass("col-4");
        $("#info2 .row div, #info3 .row div, #info4 .row div").removeClass("col-12").addClass("col-6");
    
    } else if (size2.matches && window.location.href == location3) {
        // $(".switch").removeClass("col-xs-12").addClass("col-6");
        $("#background").hide()
        $("#root").addClass("background4");

    } else if (size2.matches && window.location.href == location4) {
        $(".switch").removeClass("col-xs-12").addClass("col-6");
        // $("#background").hide();
        // $("#root").addClass("background5");
       
    } else if (size2.matches && window.location.href == location5){
        $("#landingImgDesk").show();
        $("#landingImgMobile").hide();
    } 

    if (size3.matches) {
        $(".response1600").removeClass("col-6").addClass("col-12").css("margin-left", "0px");
        $(".response16002").hide();
        $(".response16003").show();
        $(".newsImageDiv").removeClass("col-4").addClass("responsive col-12");
        $(".reasonsRow div").removeClass("col-6").addClass("col-12");
        $("#MTConnectSection div.col-6").removeClass("col-6").addClass("col-12");
        $("#visualizeDataText").removeClass("col-6").addClass("col-12");
        $("#visualizeDataImg").removeClass("col-6").addClass("col-12");

    }

   }

  mediaQuery(size);
  mediaQuery(size2);
  mediaQuery(size3)
  size.addListener(mediaQuery);
  size2.addListener(mediaQuery);
  size3.addListener(mediaQuery);

});
