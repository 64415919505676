export const products = [
    {
      name: "Smart Industy and Smart Factory? I'm confused.",
      landingName: "Industry 4.0",
      price: '$1,000',
      landingDescription: "Industry 4.0 represents the fourth revolution in industrial manufacturing.  The first stage introduced steam powered mechanization. The second stage introduced mass production via assembly lines, and the third stage introduced computer technology.  \nIndustry 4.0 builds on the industrial revolutions of the past to provide data-driven solutions that help make all shop owners more efficient, no matter the scale. Want to be as efficient as possible while maintaining costs and well-established practices? Industry 4.0 can help with that.",
      description: 'Used to retrieve manufacturing data for remote viewing.',
      image: "./assets/IOTimage.png"
    },
    {
      name: 'Industrial Internet of Things',
      landingName: "IIOT",
      price: '5 Trillion Euros',
      landingDescription: 'IIOT stands for the the "Industrial Internet of Things", which is a subset of a larger concept called the Internet of Things. In short, IIOT helps shop owners and operators collect more manufacturing data at a faster speed so everyone can get an accurate view of how their shop is running. Want to identify efficiency gaps in your shop? IIOT can help you with that.' ,
      description: "Learn more about how smart manufacturing can benefit your job shop.",
      image: "./assets/newsImage2.jpg"
    },
    {
      name: 'Alexa Option',
      landingName: "Automation",
      price: '$10,000',
      landingDescription: "Automation is not a new term in the Manufacturing sector. In fact, some job shops have shunned the word thinking that automation is only for high volume production and that the headaches involved are just too much. In the past, that may have been true. In the present, that is not true at all. Manufacturing automation is not only for high volume/low mix shops anymore. In fact, there's never been a better time to look into automation. Want to save money while increasing production? Automation can help with that.  ",
      description: "Ever wonder what it's like to warm-up your machine on your ride into work?",
      image: "./assets/newsImage3.jpg"
    },
  ];


  // export const products = [
  //   {
  //     name: 'MTConnect',
  //     price: '$1,000',
  //     description: 'Used to retrieve manufacturing data for remote viewing.',
  //     image: "./assets/IOTimage.png"
  //   },
    
  //   {
  //     name: 'Alexa Option',
  //     price: '$10,000',
  //     description: "Ever wonder what it's like to warm-up your machine on your ride into work?"
  //   },
  //   {
  //     name: 'Industrial Internet of Things',
  //     price: '5 Trillion Euros',
  //     description: "Learn more about how smart manufacturing can benefit your job shop."
  //   }
  // ];