export const jumboinfo = [
  
    {
        title: "Manufacture Smarter",
        info: "Hurco is leading in CNC innovation by leveraging our Industry 4.0 connection to make you and your machine work better, faster, and smarter."

    }
];

export const jumboinfo2 = [

    {
        title: "Alexa, Tell Hurco to Warm-Up",
        info: "Let Alexa do some work for you. You deserve it. "
    }
];

export const jumboinfo3 = [

    {
        title: "You're Ready. We're Here to Help.",

        info: "You know what matters and what doesn't when it comes to running your shop. We can help you evaluate how technology can help you instead of slowing you down."
    }
];