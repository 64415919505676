import { Component, OnInit } from '@angular/core';
declare var jQuery:any;
declare var $ :any;

@Component({
    selector: 'app-automation',
    templateUrl: './automation.component.html',
    styleUrls: ['./automation.component.css']
})

export class AutomationComponent implements OnInit {
    constructor() { }

    ngOnInit() { 
        var noWrap = document.getElementById("masterDiv");
        noWrap.classList.remove("wrapper");

        document.getElementById('root').classList.add('background4');

        window.scrollTo(0,0);
    }

    ngOnDestroy() {
        document.getElementById('root').classList.remove("background4"); 
    }

}

//page animation control
$(document).ready(function(){
    var options = {
      animateThreshold: 150,
      scrollPollInterval: 20
  }
              $('.aniview').AniView(options);
  
          });



$(document).ready(function(){
    var size = window.matchMedia("(max-width: 500px)");
    var sizeAuto = window.matchMedia("(max-width: 1500px");


   function mediaQuery(x) {
       if (size.matches || sizeAuto.matches){
      
        $(".switch").removeClass("col-6").addClass("col-xs-12");
        $("#cobotImg").addClass("responsive");
        $("#automationVideo").addClass("responsive");
        $("#inActionSection div img").addClass("responsive");
        
    } else  {
        $(".switch").removeClass("col-xs-12").addClass("col-6");
        $("#partHandlingImg").removeClass("responsive");
        $("#palletHandlingImg").removeClass("responsive");
        $("#cobotImg").removeClass("responsive");
        $("#automationVideo").removeClass("responsive");
        $("#inActionSection a img").removeClass("responsive");
    
    } 

   }

   function mediaQuery2(x) {
       if (sizeAuto.matches) {
        $("#cobotImg").addClass("responsive");
        $("#palletHandlingImg").addClass("responsive");
        $("#partHandlingImg").addClass("responsive");
       } else {
        $("#cobotImg").removeClass("responsive");
        $("#palletHandlingImg").removeClass("responsive");
        $("#partHandlingImg").removeClass("responsive");
       }
   }

  mediaQuery(size);
  mediaQuery2(sizeAuto);
  size.addListener(mediaQuery);
  sizeAuto.addListener(mediaQuery2);

});