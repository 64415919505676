import { Component, OnInit } from '@angular/core';
declare var jQuery:any;
declare var $ :any;

@Component({
    selector: 'app-mtconnect',
    templateUrl: './mtconnect.component.html',
    styleUrls: ['./mtconnect.component.css'],

})

export class MTConnectComponent implements OnInit {
    constructor() { }

    ngOnInit() { 
        var noWrap = document.getElementById("masterDiv");
        noWrap.classList.remove("wrapper");

    }

    ngOnDestroy() {
        document.getElementById('root').classList.remove("background5"); 
    }

}

$(document).ready(function(){
    var size = window.matchMedia("(max-width: 1600px)");
    var size2 = window.matchMedia("(max-width: 500px)");
    var size3 = window.matchMedia("(max-width: 1000px)");

   function mediaQuery(x) {
       if (size.matches){
      
        $("#mtConnectInfoGraphic").addClass("responsive");
        
    } else {
        $("#mtConnectInfoGraphic").removeClass("responsive");
    } 
      if (size2.matches){
          $("#visualizeDataText").removeClass("col-6").addClass("col-12");
          $("#visualizeDataImg").removeClass("col-6").addClass("col-12");
      } else {
        $("#visualizeDataText").addClass("col-6").removeClass("col-12");
        $("#visualizeDataImg").addClass("col-6").removeClass("col-12");
      }
      
      if (size3.matches) {
        $("#visualizeDataText").removeClass("col-6").addClass("col-12");
        $("#visualizeDataImg").removeClass("col-6").addClass("col-12");
      } else {
        $("#visualizeDataText").addClass("col-6").removeClass("col-12");
        $("#visualizeDataImg").addClass("col-6").removeClass("col-12");
      }

   }

  mediaQuery(size);
  size.addListener(mediaQuery);



});
          