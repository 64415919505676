import { Component, OnInit, } from '@angular/core';
import { products } from '../products';
import { Router, NavigationEnd, RouterEvent } from '@angular/router'
declare var jQuery:any;
declare var $ :any;


@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.css'],
})

export class ProductsComponent implements OnInit {
    products = products;
    
    constructor(private router: Router) { 
        
    }


    ngOnInit() { 
       var noWrap = document.getElementById("masterDiv");
       noWrap.classList.add("wrapper");
        
    }
}

